import { z } from "zod"
import { c } from "./contract-instance"
import { AttachFileSchema, FileSchema } from "./files"
import { DataMappingSchema } from "./providers"

export const InvoiceStatusEnumSchema = z
	.enum( [
		"DRAFT",
		"PROCESSING",
		"PROCESSING_FAILED",
		"VERIFICATION_PENDING",
		"MERGING",
		"MERGED",
		"MERGING_FAILED",
		"UPLOADING_FILES",
	] )
	.openapi( {
		title: "Invoice Status",
	} )

export const CreateInvoiceStatusEnum = InvoiceStatusEnumSchema.exclude( [
	"MERGED",
	"MERGING",
	"MERGING_FAILED",
	"PROCESSING_FAILED",
	"VERIFICATION_PENDING",
] ).default( "DRAFT" )

export const ListInvoiceStatusSchema = z.union( [
	z.enum( [
		"UPLOADING_FILES",
		"PROCESSING",
		"EXTRACTING_INVOICE_DATA",
		"VALIDATION_PENDING",
		"PROCESSING_FAILED",
		"MERGED_TO_MASTER_DATA",
	] ),
	z.string(),
] )

export const changelogItemSchema = z.object( {
	column_name: z.string(),
	old_value: z.union( [ z.string(), z.number(), z.null() ] ),
	new_value: z.union( [ z.string(), z.number(), z.null() ] ),
	timestamp: z.string(),
} )

export const changelogInvoiceStatus = z.enum( [
	"VALIDATION_PENDING",
	"AUTO_VERIFIED",
	"MERGED_TO_MASTER",
	"MERGING_TO_MASTER",
] )

export type ListInvoiceStatusType = z.infer<typeof ListInvoiceStatusSchema>

export const UserSchema = z.object( {
	user_id: z.number(),
	user_email: z.string().email(),
} )

export const ExtractionSetting = DataMappingSchema.pick( { id: true } ).extend( {
	should_extract: z.boolean(),
} )

export const ExtractionStatusEnumSchema = z.enum( [ "Success", "Error", "Processing" ] )

export const ExtractionFile = FileSchema.extend( {
	status: ExtractionStatusEnumSchema,
} )

export const ExtractedData = z.object( {
	accuracy: z.number().min( 0 ).max( 100 ).nullable(),
	field_name: z.string(),
	value: z.union( [ z.string(), z.number(), z.null() ] ),
} )

export type ExtractedDataType = z.infer<typeof ExtractedData>

export const ValidatedData = ExtractedData

export type ValidatedDataType = z.infer<typeof ExtractedData>

export const MappingDataSchema = z.object( {
	active: z.boolean(),
	data_type: z.enum( [ "string", "date", "float" ] ),
	invoice_field_name: z.string(),
	master_data_field_name: z.string(),
} )

export type MappingDataType = z.infer<typeof MappingDataSchema>

export const InvoiceExtractedData = z.object( {
	file_url: z.string(),
	extracted_data: ExtractedData.array(),
	original_data: z.record( z.string().nullable() ),
	invoice_processing_id: z.number(),
	is_validated: z.boolean().catch( false ),
} )

export const InvoiceValidatedData = z.object( {
	file_url: z.string(),
	extracted_data: ExtractedData.array(),
	invoice_processing_id: z.number(),
	is_validated: z.boolean().catch( false ),
} )

export type InvoiceExtractedDataType = z.infer<typeof InvoiceExtractedData>

// Create ValidatedDataSchema by omitting 'original_data' from InvoiceExtractedData
const ValidatedDataSchema = InvoiceValidatedData

export type ValidatedData = z.infer<typeof ValidatedDataSchema>

const GetBulkInvoiceExtractedData = InvoiceExtractedData.array().array()
const GetBulkValidatedData = ValidatedDataSchema.array().array()

export type GetBulkInvoiceExtractedDataType = z.infer<typeof GetBulkInvoiceExtractedData>
export type GetBulkValidatedDataType = z.infer<typeof GetBulkValidatedData>

export const GetBulkInvoiceOkResponse = z.object( {
	id: z.number(),
	provider: z.object( {
		id: z.number(),
		name: z.string(),
		icon: z.string(),
		mapping_data: MappingDataSchema.array(),
		mapping_schema: MappingDataSchema.array().catch( [] ),
		supported_formats: z.array( z.string() ),
		created_by: z.string(),
		status: z.string(),
	} ),
	uploaded_files: FileSchema.pick( { url: true, size: true } ).array().catch( [] ),
	error_details: z.string().nullable(),
	extracted_data: z.union( [ GetBulkInvoiceExtractedData, z.object( {} ).strict() ] ),
	validated_data: GetBulkValidatedData.catch( [] ),
	status: InvoiceStatusEnumSchema,
	created_by: UserSchema,
	merge_timestamp: z.null(),
	validation_progress: z.number().min( 0 ).max( 100 ).catch( 0 ),
	created_at: z.string().datetime(),
	validated_count: z.number().min( 0 ).int(),
	total_count: z.number().min( 0 ).int(),
} )

export const InvoiceSchema = z.object( {
	id: z.number(),
	customer: z.string(),
	billing_software: z.string(),
	invoice_extracted_count: z.number(),
	created_by: z.object( { user_email: z.string(), user_id: z.number() } ),
	status: ListInvoiceStatusSchema,
	invoice_files: z.object( {
		size: z.number(),
		url: z.string(),
	} ),
	pre_work_file: z.object( {
		size: z.number(),
		url: z.string(),
	} ),
	project: z.number(),
	processing_error_details: z.string().nullable(),
	created_at: z.string(),
} )

export const ListBulkInvoicesOkResponse = z.object( {
	count: z.number(),
	next: z.union( [ z.string(), z.number(), z.null() ] ),
	previous: z.union( [ z.string(), z.number(), z.null() ] ),
	results: InvoiceSchema.array(),
} )

export const CreateInvoiceSchema = z.object( {
	provider: z.string().uuid(),
	files: AttachFileSchema.array(),
} )

export const PdfFileRef = z.object( {
	data: z.object( { file_svg: z.string() } ),
	file_type: z.literal( "pdf" ),
} )

export const CsvFileRef = z.object( {
	data: z.record( z.string(), z.string().nullable() ),
	file_type: z.literal( "csv" ),
} )

export const FileRef = z.discriminatedUnion( "file_type", [ PdfFileRef, CsvFileRef ] )

export const ReferenceChunkSchema = z.object( {
	file_ref: FileRef,
} )

export const InvoiceFilesSchema = z.object( {
	id: z.number(),
	invoice_files: z.object( {
		size: z.number(),
		url: z.string(),
	} ),
	pre_work_file: z.object( {
		size: z.number(),
		url: z.string(),
	} ),
	status: z.string(),
	created_by: z.object( {
		user_id: z.number(),
		user_email: z.string().email(),
	} ),
	created_at: z.string().datetime(),
	project: z.number(),
	invoice_extracted_count: z.number(),
	processing_error_details: z.string().nullable(),
	customer: z.string().nullable(),
	billing_software: z.string().nullable(),
} )

export const invoiceFileSchema = z.object( {
	url: z.string(),
	size: z.number(),
} )

export const ValidatedInvoiceSchema = z.object( {
	auto_validated_count: z.number(),
	billing_software: z.string(),
	created_at: z.string(),
	created_by: UserSchema,
	customer: z.string(),
	id: z.number(),
	invoice_extracted_count: z.number(),
	merged_count: z.number(),
	pending_count: z.number(),
	processing_error_details: z.string().nullable(),
	invoice_files: invoiceFileSchema,
	pre_work_file: invoiceFileSchema,
	status: z.string(),
	reference_chunk_info: ReferenceChunkSchema,
	project: z.number(),
} )

export const ListInvoicesForValidationOkResponse = z.object( {
	count: z.number(),
	next: z.union( [ z.string(), z.number(), z.null() ] ),
	previous: z.union( [ z.string(), z.number(), z.null() ] ),
	results: ValidatedInvoiceSchema.omit( { reference_chunk_info: true } ).array(),
} )

export type ValidatedInvoiceSchemaType = Omit<
	z.infer<typeof ValidatedInvoiceSchema>,
	"reference_chunk_info"
>

const files = c.router(
	{
		listInvoices: {
			method: "GET",
			responses: {
				200: ListBulkInvoicesOkResponse,
			},
			query: z.object( {
				project: z.number().optional(),
				page: z.number().optional(),
				page_size: z.number().optional(),
				status: InvoiceStatusEnumSchema.optional(),
				status__in: z.string().optional(),
			} ),
			path: "/all",
		},
		listInvoicesForValidation: {
			method: "GET",
			path: "/validation/",
			query: z.object( {
				project: z.number().optional(),
				page_size: z.number().optional(),
				page: z.number().optional(),
			} ),
			responses: {
				200: ListInvoicesForValidationOkResponse,
			},
		},
		deleteInvoice: {
			method: "DELETE",
			pathParams: z.object( { invoice_id: z.number() } ),
			responses: {
				200: z.string(),
			},
			path: "/:invoice_id",
			body: z.any(),
		},
		getInvoice: {
			method: "GET",
			pathParams: z.object( { invoice_id: z.string() } ),
			responses: {
				200: GetBulkInvoiceOkResponse,
			},
			path: "/:invoice_id/",
		},
		updateInvoice: {
			method: "PATCH",
			path: "/:invoice_id/",
			contentType: "multipart/form-data",
			body: c.type<{
				validated_data?: GetBulkInvoiceExtractedDataType
				status?: z.infer<typeof CreateInvoiceStatusEnum>
				validated_count?: number
			}>(),
			responses: {
				200: z.object( {
					message: z.string(),
					details: z
						.object( {
							status: InvoiceStatusEnumSchema,
						} )
						.passthrough(),
				} ),
			},
		},
	},
	{
		pathPrefix: "/files",
	},
)

export const CreateInvoiceBody = z.object( {
	pre_work_file: z.array( z.instanceof( File ) ).nonempty(),
	uploaded_files: z.array( z.instanceof( File ) ).nonempty(),
	file_format: z.string(),
	project: z.string().optional(),
} )

export type CreateInvoiceBodyType = z.infer<typeof CreateInvoiceBody>

export const CreateInvoiceCreatedResponse = z.object( {
	message: z.string(),
	details: z.object( {
		id: z.number(),
		customer: z.string(),
		billing_software: z.string(),
		file_format: z.string(),
		invoice_files: z.string(),
		pre_work_file: z.string(),
		project: z.number(),
		status: InvoiceStatusEnumSchema,
		created_by: UserSchema,
	} ),
} )

export const ProjectsResultsSchema = z.object( {
	created_at: z.string(),
	id: z.number(),
	project_name: z.string(),
	project_number: z.string(),
	project_description: z.string(),
	updated_at: z.string(),
} )

export type ProjectSchemaType = z.infer<typeof ProjectsResultsSchema>

export const ListProjectsResponse = z.object( {
	count: z.number(),
	next: z.union( [ z.string(), z.number(), z.null() ] ),
	previous: z.union( [ z.string(), z.number(), z.null() ] ),
	results: z.array( ProjectsResultsSchema ),
} )

export const ProjectSchema = z.object( {
	id: z.number(),
	project_name: z.string(),
	project_number: z.string(),
	project_description: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
} )

export const GetProjectsOkResponseSchema = z.array( ProjectSchema ).nonempty()

export const CreateProjectRequestSchema = z.object( {
	project_name: z.string(),
	project_description: z.string(),
	project_number: z.string().optional(),
} )

export const CreateProjectOkResponseSchema = z.object( {
	id: z.number(),
	project_name: z.string(),
	project_description: z.string(),
	project_number: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
} )

export const DeleteProjectResponseSchema = z.string()

// getExtractedInvoice schema

export const InvoiceDataFieldSchema = z.object( {
	accuracy: z.number(),
	field_name: z.string(),
	index: z.number(),
	is_critical: z.boolean().optional(),
	value: z.union( [ z.string(), z.number(), z.null() ] ),
} )

export type ResultItemType = z.infer<typeof GetExtractedInvoiceResultItemSchema>

export type ExtractedInvoiceOkResponseType = z.infer<typeof GetExtractedInvoiceOkResponse>

export type InvoiceDataFieldType = z.infer<typeof InvoiceDataFieldSchema>

export const GetExtractedInvoiceDataSchema = z.object( {
	avg_accuracy: z.number(),
	changelog: z.union( [ z.record( z.unknown() ).default( {} ), z.array( changelogItemSchema ) ] ),
	created_at: z.string(),
	id: z.number(),
	invoice_data: z.array( InvoiceDataFieldSchema ),
	invoice_files: InvoiceFilesSchema,
	metadata: z.record( z.unknown() ).default( {} ),
	merged_at: z.string().nullable(),
	reference_chunk_info: z.record( z.unknown() ).default( {} ),
	status: z.string(),
} )

const GetExtractedInvoiceResultItemSchema = z.object( {
	avg_accuracy: z.number().nullable(),
	changelog: z.union( [ z.record( z.unknown() ).default( {} ), z.array( changelogItemSchema ) ] ),
	created_at: z.string().datetime(),
	id: z.number(),
	invoice_data: InvoiceDataFieldSchema.array(),
	invoice_files: InvoiceFilesSchema,
	merged_at: z.string().datetime().nullable(),
	metadata: z.record( z.unknown() ).default( {} ),
	reference_chunk_info: z.record( z.unknown() ).default( {} ),
	status: z.string(),
} )

export const GetExtractedInvoiceOkResponse = z.object( {
	count: z.number().int().nonnegative(),
	next: z.string().url().nullable(),
	previous: z.string().url().nullable(),
	results: z.array( GetExtractedInvoiceResultItemSchema ).default( [] ),
} )

export const UpdateExtractedInvoiceDataSchema = InvoiceDataFieldSchema.array()

export const ExtractedInvoiceResultItemSchema = z.object( {
	id: z.number(),
	invoice_files: InvoiceFilesSchema,
	avg_accuracy: z.number().nullable(),
	reference_chunk_info: z.record( z.unknown() ).default( {} ),
	merged_at: z.string().datetime().nullable(),
	created_at: z.string().datetime(),
	status: z.string(),
	metadata: z.record( z.unknown() ).default( {} ),
	invoice_data: z.array( InvoiceDataFieldSchema ),
	changelog: z.union( [ z.record( z.unknown() ).default( {} ), z.array( changelogItemSchema ) ] ),
} )

export const UpdateExtractedInvoiceSchema = z.object( {
	invoice_data: InvoiceDataFieldSchema.array(),
	changelog: z.array( changelogItemSchema ).default( [] ),
	status: changelogInvoiceStatus,
	id: z.number(),
} )

export const invoices = c.router(
	{
		createInvoice: {
			method: "POST",
			path: "/upload/",
			contentType: "multipart/form-data",
			body: c.type<{
				invoice_files: File
				pre_work_file: File[]
				file_format: string
				project: string
			}>(),
			// pathParams: z.object( { project_id: z.number() } ),
			responses: {
				200: CreateInvoiceCreatedResponse,
			},
		},
		completeValidation: {
			method: "GET",
			path: "/merge/:invoice_id/",
			responses: {
				200: z.any(),
			},
		},
		listProjects: {
			method: "GET",
			path: "/projects",
			responses: {
				200: ListProjectsResponse,
			},
		},
		createProject: {
			method: "POST",
			path: "/projects/",
			summary: "Create a new project",
			contentType: "application/json",
			body: c.type<z.infer<typeof CreateProjectRequestSchema>>(),
			responses: {
				200: CreateProjectOkResponseSchema,
			},
		},
		updateProject: {
			method: "PATCH",
			path: "/projects/:project_id/",
			summary: "Update the project",
			contentType: "application/json",
			pathParams: z.object( { project_id: z.number() } ),
			body: c.type<z.infer<typeof CreateProjectRequestSchema>>(),
			responses: {
				200: CreateProjectOkResponseSchema,
			},
		},
		deleteProject: {
			method: "DELETE",
			path: "/projects/:project_id/",
			summary: "Delete the project",
			contentType: "application/json",
			pathParams: z.object( { project_id: z.number() } ),
			body: z.any(),
			responses: {
				200: DeleteProjectResponseSchema,
			},
		},
		getExtractedInvoice: {
			method: "GET",
			path: "/extracted",
			summary: "Get the extracted invoice details",
			query: z.object( {
				page: z.number().optional(),
				page_size: z.number().optional(),
				invoice_files: z.number(),
			} ),
			responses: {
				200: GetExtractedInvoiceOkResponse,
			},
		},

		updateExtractedInvoice: {
			method: "PATCH",
			path: "/extracted/:extracted_invoice_id/",
			pathParams: z.object( { extracted_invoice_id: z.number() } ),
			summary: "Update the extracted fields for invoice",
			body: z.object( {
				invoice_data: UpdateExtractedInvoiceDataSchema,
				changelog: z.array( changelogItemSchema ),
				status: changelogInvoiceStatus,
			} ),
			responses: {
				200: UpdateExtractedInvoiceSchema,
			},
		},

		files,
	},
	{
		pathPrefix: "/invoice",
	},
)
