export type FeatureFlags = {
	compare_my_site: boolean
	chats: boolean
	emails: boolean
	outreach: boolean
	marketing: boolean
	scribe_ai: boolean
	forecast_agent: boolean
	security_checks_cloud_apps: boolean
	db_gpt: boolean
	chart_generator: boolean
	brief_gpt: boolean
	ai_tutor: boolean
	data_collection_ai: boolean
	structured_doc_gen: boolean
	document_correction: boolean
	customer_analytics: boolean
	multi_agent: boolean
	sales_pitch_generator: boolean
	multi_agent_bot: boolean
	docs_comparison: boolean
	legal_jargon: boolean
	lease_agreement: boolean
	widgets: boolean
	flowchart: boolean
	document_generator: boolean
	summarisation_agent: boolean
	groq_summarisation_agent: boolean
	chart_interpreter: boolean
	floor_generator: boolean
	document_process: boolean
	contract_intelligence: boolean
	meeting_scheduling: boolean
	medical_guardrail: boolean
	text_to_speech: boolean
	speech_to_text: boolean
	project_management: boolean
	enabled_google_oauth: boolean
	guardrails: boolean
	posthog: boolean
	casestudy: boolean
	generate_invoice: boolean
	market_intelligence: boolean
	multi_model_config: boolean
	customer_service: boolean
	energy: boolean
	nullable_kb: boolean
	employee_onboarding: boolean
	kb_auto_refresh: boolean
	chain_of_thoughts: boolean
	procore_automation_prod: boolean
	procore_automation: boolean
	trademark_agent: boolean
	water_billing_usage: boolean
	fishbowl_uat: boolean
	fishbowl_prod: boolean
}

export type Vars = {
	mode: string
	email_api: string
	marketing_api: string
	customer_analytics_api: string
	api: string
	ws: string
	legal_jargon_api: string
	google: {
		clientId: string
		redirectUri: string
	}
	disclaimers: {
		collection: string
		team: string
		guardrails: string
		google_drive: string
	}
	cotsEndpoint: string
	psi_api: string
	scribe_ai_be_api: string
	forecast_agent_be_api: string
	security_checks_cloud_apps_be_api: string
	db_gpt_be_api: string
	chart_generator_be_api: string
	marketing_prompt_min: number
	feature_flags: FeatureFlags
	enterprise_link: string
	sales_pitch_generator_api: string
	docs_comparison_be_api: string
	flowchart_be_api: string
	lease_agreement_be_api: string
	document_generator_be_api: string
	summarization_agent_be_api: string
	chart_interpreter_be_api: string
	floor_generator_be_api: string
	document_process_be_api: string
	contract_intelligence_be_api: string
	meeting_scheduling_be_api: string
	text_to_speech_be_api: string
	speech_to_text_be_api: string
	project_management_be_api: string
	casestudy_be_api: string
	invoice_generator_be_api: string
	market_intelligence_be_api: string
	customer_service_be_api: string
	energy_be_api: string
	posthog_api_key: string
	employee_onboarding_be_api: string
	procore_automation_be_api: string
	procore_automation_be_prod_api: string
	fishbowl_dev_api: string
	fishbowl_uat_api: string
	fishbowl_prod_api: string
	places_autocomplete_api: string
	locaitionMatterOrgId: number
	pusher: {
		wsHost: string
		wsPort: number
		httpHost: string
		httpPort: number
		appKey: string
	}
	isStandaloneDeployment: boolean
	water_billing_default_validated: boolean
	executive_report_provider_id: number
}

declare global {
	interface Window {
		vars?: Vars
	}
}

const mode = import.meta.env.MODE

const development = {
	posthog_api: "phc_cUeNgw5cIyJMi9nsxk2a90UNLfRd5rVwAbKH36BavAA",
	mode,
	cotsEndpoint: "v1/query/chain_of_thought/",
	psi_api: "https://psi-automation-service.azurewebsites.net",
	email_api: "https://email-svc-dev.attri.ai/api/v1",
	marketing_api: "https://marketing-ai-agent-be-dev.attri.ai/api/v1",
	customer_analytics_api: "https://customer-analysis-be-dev.attri.ai/",
	api: "https://gen-api-dev.attri.ai",
	ws: "wss://gen-api-dev.attri.ai",
	legal_jargon_api: "https://legal-jargon-be-dev.attri.ai",
	lease_agreement_be_api: "https://lease-agreement-agent-dev.attri.ai",
	google: {
		clientId: "175252538116-roj76u6cclpihn523tmh2h1hldnllnii.apps.googleusercontent.com",
		redirectUri: "http://localhost:8000/user/google-auth/callback/",
	},
	disclaimers: {
		collection: "* You can only edit/delete resources that you own",
		team: "* You can only edit/delete resources that you own",
		guardrails: "* You can only edit/delete guardrails that you own",
		google_drive: "Supported Document types - doc, docx",
	},
	scribe_ai_be_api: "https://doctor-scribe-be-dev.attri.ai",
	forecast_agent_be_api: "https://budget-forecasting-dev.attri.ai",
	security_checks_cloud_apps_be_api: "https://prowler-be-dev.attri.ai",
	db_gpt_be_api: "https://dbgpt-dev.attri.ai",
	chart_generator_be_api: "https://charts-agent-dev.attri.ai",
	docs_comparison_be_api: "https://similarity-be-dev.attri.ai",
	flowchart_be_api: "https://flowchart-be-dev.attri.ai",
	document_generator_be_api: "https://structured-doc-be-dev.attri.ai",
	summarization_agent_be_api: "https://summarisation-agent-be-dev.attri.ai",
	chart_interpreter_be_api: "https://chart-interpret-be-dev.attri.ai",
	floor_generator_be_api: "https://floormap-be-dev.attri.ai",
	document_process_be_api: "https://poc-idp.attri.ai",
	contract_intelligence_be_api: "https://contract-be-dev.attri.ai",
	meeting_scheduling_be_api: "https://meet-be-dev.attri.ai",
	text_to_speech_be_api: "https://tts-be-dev.attri.ai",
	speech_to_text_be_api: "https://stt-be-dev.attri.ai",
	project_management_be_api: "https://todo-be-dev.attri.ai",
	casestudy_be_api: "https://casestudy-be-dev.attri.ai",
	invoice_generator_be_api: "https://invoice-be-dev.attri.ai",
	market_intelligence_be_api: "https://marketintelligence-be-dev.attri.ai",
	customer_service_be_api: "https://customerservice-be-dev.attri.ai",
	energy_be_api: "https://energy-be-dev.attri.ai",
	employee_onboarding_be_api: "http://employee-onboarding-be-dev.attri.ai",
	procore_automation_be_api: "https://bulleys-procore-automation.azurewebsites.net",
	procore_automation_be_prod_api: "https://procore-automation-prod-600074948305.us-east4.run.app",
	fishbowl_dev_api: "https://fishbowl-integration-dev-600074948305.us-east4.run.app",
	fishbowl_uat_api: "https://fishbowl-integration-uat-600074948305.us-east4.run.app",
	fishbowl_prod_api: "https://fishbowl-integration-prod-600074948305.us-east4.run.app",
	places_autocomplete_api: "AIzaSyC9EfAqfGF2FQSW02iZLNSO6jaZW3fBv28",
	locaitionMatterOrgId: 4,
	marketing_prompt_min: 30,
	feature_flags: {
		compare_my_site: true,
		chats: true,
		ai_tutor: true,
		brief_gpt: true,
		data_collection_ai: true,
		document_correction: true,
		emails: true,
		forecast_agent: true,
		security_checks_cloud_apps: true,
		db_gpt: true,
		chart_generator: true,
		marketing: true,
		outreach: true,
		scribe_ai: true,
		structured_doc_gen: true,
		customer_analytics: true,
		lease_agreement: true,
		multi_agent: true,
		sales_pitch_generator: true,
		multi_agent_bot: true,
		legal_jargon: true,
		docs_comparison: true,
		widgets: true,
		flowchart: true,
		document_generator: true,
		summarisation_agent: true,
		groq_summarisation_agent: true,
		chart_interpreter: true,
		floor_generator: true,
		document_process: true,
		contract_intelligence: true,
		meeting_scheduling: true,
		medical_guardrail: true,
		text_to_speech: true,
		speech_to_text: true,
		project_management: true,
		enabled_google_oauth: true,
		guardrails: true,
		posthog: false,
		casestudy: true,
		generate_invoice: true,
		market_intelligence: true,
		multi_model_config: true,
		customer_service: true,
		energy: true,
		nullable_kb: true,
		employee_onboarding: true,
		kb_auto_refresh: true,
		chain_of_thoughts: true,
		procore_automation: true,
		procore_automation_prod: true,
		trademark_agent: true,
		water_billing_usage: true,
		fishbowl_uat: true,
		fishbowl_prod: true,
	},
	enterprise_link: "https://attri.ai/attri-ai-engine",
	sales_pitch_generator_api: "https://sales-pitch-generator-dev.attri.ai/api/v1",
	posthog_api_key: "",
	pusher: {
		wsHost: "soketi-dev.attri.ai",
		wsPort: 443,
		httpHost: "soketi-dev.attri.ai",
		httpPort: 443,
		appKey: "gen-ai-soketi-key",
	},
	isStandaloneDeployment: false,
	water_billing_default_validated: false,
	executive_report_provider_id: 2,
} as Vars

const staging = {
	posthog_api: "phc_cUeNgw5cIyJMi9nsxk2a90UNLfRd5rVwAbKH36BavAA",
	mode,
	cotsEndpoint: "v1/query/chain_of_thought/",
	psi_api: "https://psi-automation-service.azurewebsites.net",
	email_api: "https://email-svc-dev.attri.ai/api/v1",
	marketing_api: "https://marketing-ai-agent-be-dev.attri.ai/api/v1",
	customer_analytics_api: "https://customer-analysis-be-dev.attri.ai/",
	api: "https://gen-api-dev.attri.ai",
	ws: "wss://gen-api-dev.attri.ai",
	legal_jargon_api: "https://legal-jargon-be-dev.attri.ai",
	lease_agreement_be_api: "https://lease-agreement-agent-dev.attri.ai",
	google: {
		clientId: "175252538116-roj76u6cclpihn523tmh2h1hldnllnii.apps.googleusercontent.com",
		redirectUri: "https://gen-dev.attri.ai/user/google-auth/callback/",
	},
	disclaimers: {
		collection: "* You can only edit/delete resources that you own",
		team: "* You can only edit/delete resources that you own",
		guardrails: "* You can only edit/delete resources that you own",
		google_drive: "Supported Document types - DOC, DOCx, PDF, and CSV.",
	},
	scribe_ai_be_api: "https://doctor-scribe-be-dev.attri.ai",
	forecast_agent_be_api: "https://budget-forecasting-dev.attri.ai",
	security_checks_cloud_apps_be_api: "https://prowler-be-dev.attri.ai",
	db_gpt_be_api: "https://dbgpt-dev.attri.ai",
	chart_generator_be_api: "https://charts-agent-dev.attri.ai",
	docs_comparison_be_api: "https://similarity-be-dev.attri.ai",
	flowchart_be_api: "https://flowchart-be-dev.attri.ai",
	document_generator_be_api: "https://structured-doc-be-dev.attri.ai",
	summarization_agent_be_api: "https://summarisation-agent-be-dev.attri.ai",
	chart_interpreter_be_api: "https://chart-interpret-be-dev.attri.ai",
	floor_generator_be_api: "https://floormap-be-dev.attri.ai",
	document_process_be_api: "https://poc-idp.attri.ai",
	contract_intelligence_be_api: "https://contract-be-dev.attri.ai",
	meeting_scheduling_be_api: "https://meet-be-dev.attri.ai",
	text_to_speech_be_api: "https://tts-be-dev.attri.ai",
	speech_to_text_be_api: "https://stt-be-dev.attri.ai",
	project_management_be_api: "https://todo-be-dev.attri.ai",
	casestudy_be_api: "https://casestudy-be-dev.attri.ai",
	invoice_generator_be_api: "https://invoice-be-dev.attri.ai",
	market_intelligence_be_api: "https://marketintelligence-be-dev.attri.ai",
	customer_service_be_api: "https://customerservice-be-dev.attri.ai",
	energy_be_api: "https://energy-be-dev.attri.ai",
	employee_onboarding_be_api: "http://employee-onboarding-be-dev.attri.ai",
	procore_automation_be_api: "https://bulleys-procore-automation.azurewebsites.net",
	procore_automation_be_prod_api: "https://procore-automation-prod-600074948305.us-east4.run.app",
	fishbowl_dev_api: "https://fishbowl-integration-dev-600074948305.us-east4.run.app",
	fishbowl_uat_api: "https://fishbowl-integration-uat-600074948305.us-east4.run.app",
	fishbowl_prod_api: "https://fishbowl-integration-prod-600074948305.us-east4.run.app",
	places_autocomplete_api: "AIzaSyC9EfAqfGF2FQSW02iZLNSO6jaZW3fBv28",
	locaitionMatterOrgId: 4,
	marketing_prompt_min: 30,
	feature_flags: {
		compare_my_site: true,
		chats: true,
		ai_tutor: true,
		brief_gpt: true,
		data_collection_ai: true,
		document_correction: true,
		emails: true,
		forecast_agent: true,
		security_checks_cloud_apps: true,
		db_gpt: true,
		chart_generator: true,
		marketing: true,
		outreach: false,
		scribe_ai: true,
		structured_doc_gen: true,
		customer_analytics: true,
		lease_agreement: true,
		multi_agent: true,
		sales_pitch_generator: true,
		multi_agent_bot: true,
		legal_jargon: true,
		docs_comparison: true,
		widgets: true,
		flowchart: true,
		document_generator: true,
		summarisation_agent: true,
		groq_summarisation_agent: true,
		chart_interpreter: true,
		floor_generator: true,
		document_process: true,
		contract_intelligence: true,
		meeting_scheduling: true,
		medical_guardrail: false,
		text_to_speech: true,
		speech_to_text: true,
		project_management: true,
		enabled_google_oauth: true,
		guardrails: true,
		posthog: false,
		casestudy: true,
		generate_invoice: true,
		market_intelligence: true,
		multi_model_config: true,
		customer_service: true,
		energy: true,
		nullable_kb: true,
		employee_onboarding: true,
		kb_auto_refresh: true,
		chain_of_thoughts: true,
		procore_automation: true,
		procore_automation_prod: true,
		trademark_agent: true,
		water_billing_usage: true,
		fishbowl_uat: true,
		fishbowl_prod: true,
	},
	enterprise_link: "https://attri.ai/attri-ai-engine",
	sales_pitch_generator_api: "https://sales-pitch-generator-dev.attri.ai/api/v1",
	posthog_api_key: "",
	pusher: {
		wsHost: "soketi-dev.attri.ai",
		wsPort: 443,
		httpHost: "soketi-dev.attri.ai",
		httpPort: 443,
		appKey: "gen-ai-soketi-key",
	},
	isStandaloneDeployment: false,
	water_billing_default_validated: false,
	executive_report_provider_id: 2,
} as Vars

const bulleys = {
	posthog_api: "phc_cUeNgw5cIyJMi9nsxk2a90UNLfRd5rVwAbKH36BavAA",
	mode,
	cotsEndpoint: "v2/query/bulleys_updated/chain_of_thought/",
	psi_api: "https://psi-automation-service.azurewebsites.net",
	email_api: "https://email-svc-dev.attri.ai/api/v1",
	marketing_api: "https://marketing-ai-agent-be.attri.ai/api/v1",
	customer_analytics_api: "https://customer-analysis-be.attri.ai",
	api: "https://llm-gen-ai-be-mg4rkol2eq-uk.a.run.app",
	ws: "wss://llm-gen-ai-be-mg4rkol2eq-uk.a.run.app",
	legal_jargon_api: "https://legal-jargon-be.attri.ai",
	lease_agreement_be_api: "https://lease-agent-be.attri.ai",
	google: {
		clientId: "111220802017-poblaaogigflookkjiadmgfqbe31jela.apps.googleusercontent.com",
		redirectUri: "https://bulleyandrews.attri.ai/user/google-auth/callback/",
	},
	disclaimers: {
		collection: "* You can only edit/delete resources that you own",
		team: "* You can only edit/delete resources that you own",
		guardrails: "* You can only edit/delete resources that you own",
		google_drive: "Supported Document types - DOC, DOCx, PDF, and CSV.",
	},
	scribe_ai_be_api: "https://doctor-scribe-be.attri.ai",
	forecast_agent_be_api: "https://budget-forecasting.attri.ai",
	security_checks_cloud_apps_be_api: "https://prowler-be.attri.ai",
	db_gpt_be_api: "https://dbgpt.attri.ai",
	chart_generator_be_api: "https://charts-be.attri.ai",
	docs_comparison_be_api: "https://similarity-be.attri.ai",
	flowchart_be_api: "https://flowchart-be.attri.ai",
	document_generator_be_api: "https://structured-doc-be-dev.attri.ai",
	summarization_agent_be_api: "https://summarisation-agent-be.attri.ai",
	chart_interpreter_be_api: "https://chart-interpret-be.attri.ai",
	floor_generator_be_api: "https://floormap-be.attri.ai",
	document_process_be_api: "https://structureddata-be.attri.ai",
	contract_intelligence_be_api: "https://contract-be.attri.ai",
	meeting_scheduling_be_api: "https://meet-be.attri.ai",
	text_to_speech_be_api: "https://tts-be.attri.ai",
	speech_to_text_be_api: "https://stt-be.attri.ai",
	project_management_be_api: "https://todo-be-dev.attri.ai",
	casestudy_be_api: "https://casestudy-be-dev.attri.ai",
	invoice_generator_be_api: "https://invoice-be.attri.ai",
	market_intelligence_be_api: "https://marketintelligence-be.attri.ai",
	customer_service_be_api: "https://customerservice-be.attri.ai",
	energy_be_api: "https://energy-be.attri.ai",
	employee_onboarding_be_api: "https://employee-onboarding-be.attri.ai",
	procore_automation_be_api: "https://procore-automation-mg4rkol2eq-uk.a.run.app",
	procore_automation_be_prod_api: "https://procore-automation-prod-600074948305.us-east4.run.app",
	fishbowl_dev_api: "https://fishbowl-integration-dev-600074948305.us-east4.run.app",
	fishbowl_uat_api: "https://fishbowl-integration-uat-600074948305.us-east4.run.app",
	fishbowl_prod_api: "https://fishbowl-integration-prod-600074948305.us-east4.run.app",
	places_autocomplete_api: "AIzaSyC9EfAqfGF2FQSW02iZLNSO6jaZW3fBv28",
	marketing_prompt_min: 30,
	locaitionMatterOrgId: 4,
	feature_flags: {
		compare_my_site: false,
		chats: true,
		ai_tutor: false,
		brief_gpt: false,
		data_collection_ai: false,
		document_correction: false,
		emails: false,
		forecast_agent: true,
		security_checks_cloud_apps: true,
		db_gpt: true,
		chart_generator: true,
		marketing: true,
		outreach: false,
		scribe_ai: true,
		structured_doc_gen: false,
		customer_analytics: true,
		lease_agreement: true,
		multi_agent: false,
		sales_pitch_generator: true,
		multi_agent_bot: true,
		legal_jargon: true,
		docs_comparison: true,
		widgets: false,
		flowchart: true,
		document_generator: false,
		summarisation_agent: true,
		groq_summarisation_agent: false,
		chart_interpreter: true,
		floor_generator: true,
		document_process: true,
		contract_intelligence: true,
		meeting_scheduling: false,
		medical_guardrail: false,
		text_to_speech: true,
		speech_to_text: true,
		project_management: true,
		enabled_google_oauth: true,
		guardrails: false,
		posthog: true,
		casestudy: true,
		generate_invoice: false,
		market_intelligence: false,
		multi_model_config: true,
		customer_service: false,
		energy: false,
		nullable_kb: true,
		employee_onboarding: false,
		kb_auto_refresh: true,
		chain_of_thoughts: true,
		procore_automation: true,
		procore_automation_prod: true,
		trademark_agent: false,
		water_billing_usage: false,
		fishbowl_uat: true,
		fishbowl_prod: true,
	},
	enterprise_link: "https://attri.ai/attri-ai-engine",
	sales_pitch_generator_api: "https://sales-pitch-generator-dev.attri.ai/api/v1",
	posthog_api_key: "phc_cUeNgw5cIyJMi9nsxk2a90UNLfRd5rVwAbKH36BavAA",
	pusher: {
		wsHost: "soketi-bulleys.attri.ai",
		wsPort: 443,
		httpHost: "soketi-bulleys.attri.ai",
		httpPort: 443,
		appKey: "gen-ai-soketi-key",
	},
	isStandaloneDeployment: false,
	water_billing_default_validated: false,
	executive_report_provider_id: 2,
} as Vars

const performance_service = {
	posthog_api: "phc_cUeNgw5cIyJMi9nsxk2a90UNLfRd5rVwAbKH36BavAA",
	mode,
	cotsEndpoint: "v1/query/chain_of_thought/",
	psi_api: "https://psi-be-auto-uat.attri.ai",
	email_api: "https://email-svc-dev.attri.ai/api/v1",
	marketing_api: "https://marketing-ai-agent-be.attri.ai/api/v1",
	customer_analytics_api: "https://customer-analysis-be.attri.ai",
	api: "https://psi-api-uat.attri.ai",
	ws: "wss://psi-api-uat.attri.ai",
	legal_jargon_api: "https://legal-jargon-be.attri.ai",
	lease_agreement_be_api: "https://lease-agent-be.attri.ai",
	google: {
		clientId: "164751288855-7juuufm43jjld2u311khf7nqs602fgs4.apps.googleusercontent.com",
		redirectUri: "https://ai.performanceservices.com/user/google-auth/callback/",
	},
	disclaimers: {
		collection: "* You can only edit/delete resources that you own",
		team: "* You can only edit/delete resources that you own",
		guardrails: "* You can only edit/delete resources that you own",
		google_drive: "Supported Document types - DOC, DOCx, PDF, and CSV.",
	},
	scribe_ai_be_api: "https://doctor-scribe-be.attri.ai",
	forecast_agent_be_api: "https://budget-forecasting.attri.ai",
	security_checks_cloud_apps_be_api: "https://prowler-be.attri.ai",
	db_gpt_be_api: "https://dbgpt.attri.ai",
	chart_generator_be_api: "https://charts-be.attri.ai",
	docs_comparison_be_api: "https://similarity-be.attri.ai",
	flowchart_be_api: "https://flowchart-be.attri.ai",
	document_generator_be_api: "https://structured-doc-be-dev.attri.ai",
	summarization_agent_be_api: "https://summarisation-agent-be.attri.ai",
	chart_interpreter_be_api: "https://chart-interpret-be.attri.ai",
	floor_generator_be_api: "https://floormap-be.attri.ai",
	document_process_be_api: "https://structureddata-be.attri.ai",
	contract_intelligence_be_api: "https://contract-be.attri.ai",
	meeting_scheduling_be_api: "https://meet-be.attri.ai",
	text_to_speech_be_api: "https://tts-be.attri.ai",
	speech_to_text_be_api: "https://stt-be.attri.ai",
	project_management_be_api: "https://todo-be-dev.attri.ai",
	casestudy_be_api: "https://casestudy-be-dev.attri.ai",
	invoice_generator_be_api: "https://invoice-be.attri.ai",
	market_intelligence_be_api: "https://marketintelligence-be.attri.ai",
	customer_service_be_api: "https://customerservice-be.attri.ai",
	energy_be_api: "https://energy-be.attri.ai",
	employee_onboarding_be_api: "https://employee-onboarding-be.attri.ai",
	procore_automation_be_api: "https://procore-automation-mg4rkol2eq-uk.a.run.app",
	procore_automation_be_prod_api: "https://procore-automation-prod-600074948305.us-east4.run.app",
	fishbowl_dev_api: "https://fishbowl-integration-dev-600074948305.us-east4.run.app",
	fishbowl_uat_api: "https://fishbowl-integration-uat-600074948305.us-east4.run.app",
	fishbowl_prod_api: "https://fishbowl-integration-prod-600074948305.us-east4.run.app",
	places_autocomplete_api: "AIzaSyC9EfAqfGF2FQSW02iZLNSO6jaZW3fBv28",
	marketing_prompt_min: 30,
	locaitionMatterOrgId: 4,
	feature_flags: {
		compare_my_site: false,
		chats: true,
		ai_tutor: false,
		brief_gpt: false,
		data_collection_ai: false,
		document_correction: false,
		emails: false,
		forecast_agent: true,
		security_checks_cloud_apps: true,
		db_gpt: true,
		chart_generator: true,
		marketing: true,
		outreach: false,
		scribe_ai: true,
		structured_doc_gen: false,
		customer_analytics: true,
		lease_agreement: true,
		multi_agent: false,
		sales_pitch_generator: true,
		multi_agent_bot: true,
		legal_jargon: true,
		docs_comparison: true,
		widgets: false,
		flowchart: true,
		document_generator: false,
		summarisation_agent: true,
		groq_summarisation_agent: false,
		chart_interpreter: true,
		floor_generator: true,
		document_process: true,
		contract_intelligence: true,
		meeting_scheduling: false,
		medical_guardrail: false,
		text_to_speech: true,
		speech_to_text: true,
		project_management: true,
		enabled_google_oauth: true,
		guardrails: false,
		posthog: true,
		casestudy: true,
		generate_invoice: false,
		market_intelligence: false,
		multi_model_config: true,
		customer_service: false,
		energy: false,
		nullable_kb: true,
		employee_onboarding: false,
		kb_auto_refresh: true,
		chain_of_thoughts: true,
		procore_automation: true,
		procore_automation_prod: true,
		trademark_agent: false,
		water_billing_usage: true,
		fishbowl_uat: false,
		fishbowl_prod: false,
	},
	enterprise_link: "https://attri.ai/attri-ai-engine",
	sales_pitch_generator_api: "https://sales-pitch-generator-dev.attri.ai/api/v1",
	posthog_api_key: "phc_cUeNgw5cIyJMi9nsxk2a90UNLfRd5rVwAbKH36BavAA",
	pusher: {
		wsHost: "soketi-psi.attri.ai",
		wsPort: 443,
		httpHost: "soketi-psi.attri.ai",
		httpPort: 443,
		appKey: "gen-ai-soketi-key",
	},
	isStandaloneDeployment: false,
	water_billing_default_validated: false,
	executive_report_provider_id: 1,
} as Vars

const VAR_MAPPING = {
	development,
	staging,
	bulleys,
	performance_service,
} as const

type Mode = keyof typeof VAR_MAPPING

const config = VAR_MAPPING[ mode as Mode ]

export default config

// @ts-ignore
window.__config__ = config
